<template>
	<div>
		<b-modal
			id="modal-update-status-member-code"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Update status member code')"
			modal-class="modal-primary"
			:no-close-on-backdrop="true"
			@ok="confirm"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationForm)"
					@reset.prevent="resetForm"
				>
					<b-row>
						<!-- Username -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="username"
								rules="required"
							>
								<b-form-group
									:label="$t('Username')"
									label-for="transactionId"
								>
									<b-form-input
										id="name"
										v-model="memberCode.username"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Bonus Code -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="bonusCode"
							>
								<b-form-group
									:label="$t('Bonus code')"
									label-for="bonusCode"
								>
									<b-form-input
										id="bonusCode"
										v-model="memberCode.bonus_code"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

                        <!-- Product -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="product"
							>
								<b-form-group
									:label="$t('Product')"
									label-for="product"
								>
									<b-form-input
										id="product"
										v-model="memberCode.product"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Amount -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="amount"
							>
								<b-form-group
									:label="`${$t('Amount')} (Gross)`"
									label-for="amount"
								>
									<b-form-input
										id="amount"
										v-model="memberCode.amount"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

                        <!-- Status -->
                        <b-col
                            cols="12"
                        >
                            <validation-provider
								#default="{ errors }"
								name="status"
							>
                                <b-form-group
                                    label-for="status"
                                >
                                    <label class="mb-1 d-block"><span class="text-danger">*</span> Status</label>
                                    <b-form-radio
                                        v-model="memberCode.status"
                                        name="status"
                                        value="2"
                                        inline
                                    >
                                        2
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="memberCode.status"
                                        name="status"
                                        value="3"
                                        inline
                                    >
                                        3
                                    </b-form-radio>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>    
						<!-- Remark -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="remark"
								rules="required"
							>
								<b-form-group
									label-for="remark"
								>
									<label for="remark"><span class="text-danger">*</span> {{ $t('Remark') }}</label>
									<b-form-textarea
										id="remark"
										v-model="memberCode.reject_reason"
										trim
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { numberFormat } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormRadio, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		memberBonusCodeDetail: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			memberCode: {
				id: null,
				username: null,
				bonus_code: null,
				product: null,
				amount: null,
                created_by: null,
				status: 2,
				reject_reason: null,
			},
		}
	},
	watch: {
		memberBonusCodeDetail: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.memberCode = {
						user_bonus_code_id: newVal.id,
						username: newVal.user ? newVal.user.username : '',
						bonus_code: newVal.bonus_code ? newVal.bonus_code.code : '',
                        product: newVal.product ? newVal.product.wallet_name : '',
						amount: newVal.amount ? numberFormat(newVal.amount / 1000) : '',
						status: newVal.status,
                        reject_reason: null,
					}
				}
			}
		}
	},
	methods: {
		validationForm() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					store.dispatch('bonus/updateStatusMemberBonusCode', this.memberCode).then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$emit('refetch-data')
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-update-status-member-code')
											this.resetForm()
										}, '500')
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error update',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		confirm(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		},
		resetForm() {
			this.$emit('update:memberBonusCodeDetail', null)
		},
	},
	setup() {

		return {
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
