var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-status-member-code","cancel-variant":"outline-secondary","ok-title":_vm.$t('Submit'),"cancel-title":_vm.$t('Close'),"ok-variant":"primary","centered":"","title":_vm.$t('Update status member code'),"modal-class":"modal-primary","no-close-on-backdrop":true},on:{"ok":_vm.confirm,"hidden":_vm.resetForm}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Username'),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"name","trim":"","disabled":""},model:{value:(_vm.memberCode.username),callback:function ($$v) {_vm.$set(_vm.memberCode, "username", $$v)},expression:"memberCode.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"bonusCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Bonus code'),"label-for":"bonusCode"}},[_c('b-form-input',{attrs:{"id":"bonusCode","trim":"","disabled":""},model:{value:(_vm.memberCode.bonus_code),callback:function ($$v) {_vm.$set(_vm.memberCode, "bonus_code", $$v)},expression:"memberCode.bonus_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Product'),"label-for":"product"}},[_c('b-form-input',{attrs:{"id":"product","trim":"","disabled":""},model:{value:(_vm.memberCode.product),callback:function ($$v) {_vm.$set(_vm.memberCode, "product", $$v)},expression:"memberCode.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('Amount')) + " (Gross)"),"label-for":"amount"}},[_c('b-form-input',{attrs:{"id":"amount","trim":"","disabled":""},model:{value:(_vm.memberCode.amount),callback:function ($$v) {_vm.$set(_vm.memberCode, "amount", $$v)},expression:"memberCode.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1 d-block"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" Status")]),_c('b-form-radio',{attrs:{"name":"status","value":"2","inline":""},model:{value:(_vm.memberCode.status),callback:function ($$v) {_vm.$set(_vm.memberCode, "status", $$v)},expression:"memberCode.status"}},[_vm._v(" 2 ")]),_c('b-form-radio',{attrs:{"name":"status","value":"3","inline":""},model:{value:(_vm.memberCode.status),callback:function ($$v) {_vm.$set(_vm.memberCode, "status", $$v)},expression:"memberCode.status"}},[_vm._v(" 3 ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"remark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"remark"}},[_c('label',{attrs:{"for":"remark"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Remark')))]),_c('b-form-textarea',{attrs:{"id":"remark","trim":""},model:{value:(_vm.memberCode.reject_reason),callback:function ($$v) {_vm.$set(_vm.memberCode, "reject_reason", $$v)},expression:"memberCode.reject_reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }